
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  data() {
    return {
      hostname: window.location.hostname,
      domain: "",
    };
  },
  beforeMount() {
    document.title = this.hostname;
    this.domain = this.getDomain();
  },
  methods: {
    getDomain() {
      if (this.hostname.indexOf(".") != this.hostname.lastIndexOf(".")) {
        const hostname = this.hostname.split(".");
        hostname.shift();
        return hostname.join(".");
      } else {
        return this.hostname;
      }
    },
  },
});
