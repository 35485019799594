<template>
  <div class="flex h-screen bg-background dark:bg-gray-800 font-mono">
    <div class="m-auto">
      <a
        class="
          md:text-3xl
          text-xl
          hover:underline
          text-black
          dark:text-gray-100
        "
        title="Send mail"
        v-bind:href="'mailto:contact@' + domain"
        >{{ this.hostname }} <span class="cursor">|</span>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  data() {
    return {
      hostname: window.location.hostname,
      domain: "",
    };
  },
  beforeMount() {
    document.title = this.hostname;
    this.domain = this.getDomain();
  },
  methods: {
    getDomain() {
      if (this.hostname.indexOf(".") != this.hostname.lastIndexOf(".")) {
        const hostname = this.hostname.split(".");
        hostname.shift();
        return hostname.join(".");
      } else {
        return this.hostname;
      }
    },
  },
});
</script>
<style>
.cursor {
  font-weight: 100;
  -webkit-animation: 2s blink step-end infinite;
  -moz-animation: 2s blink step-end infinite;
  -ms-animation: 2s blink step-end infinite;
  -o-animation: 2s blink step-end infinite;
  animation: 2s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }

  50% {
    color: #000;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }

  50% {
    color: #000;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }

  50% {
    color: #000;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }

  50% {
    color: #000;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }

  50% {
    color: #000;
  }
}
</style>
